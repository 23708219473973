import moment from 'moment'

/**
 * Price.
 * @class
 * @property {moment.Moment} time
 * @property {Number} value
 */
class Price {
  /**
   * @constructor
   * @param {String} time
   * @param {Number} value
   */
  constructor (time, value) {
    this.time = moment.utc(time)
    this.value = value
  }
}

export default Price
