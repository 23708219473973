import Vue from 'vue'
import HttpClient from '@/services/httpClient'
import createRouter from '@/router'
import createVuetifyInstance from '@/plugins/vuetify'
import DashboardRepository from '@/repositories/dashboardRepository'
import DataStorage from '@/services/storage'
import OptionsManager from '@/services/optionsManager'
import ClipboardService from '@/services/clipboard'
import PriceRepository from '@/repositories/priceRepository'
import TransactionRepository from '@/repositories/transactionRepository'
import HolderRepository from '@/repositories/holderRepository'
import NodeRepository from '@/repositories/nodeRepository'
import SettlementRepository from '@/repositories/settlementRepository'
import FormatterService from '@/services/formatter'
import CardanoScanLinksGenerator from '@/services/cardanoScanLinks'

const httpClient = new HttpClient(process.env.VUE_APP_BACKEND_URL, 5000)
const dashboardRepository = new DashboardRepository(httpClient)
const priceRepository = new PriceRepository(httpClient)
const transactionRepository = new TransactionRepository(httpClient)
const holderRepository = new HolderRepository(httpClient)
const nodeRepository = new NodeRepository(httpClient)
const settlementRepository = new SettlementRepository(httpClient)
const router = createRouter()
const optionsStorage = new DataStorage('options', localStorage)
const optionsManager = new OptionsManager(optionsStorage)
const clipboard = new ClipboardService()
const formatter = new FormatterService()
const cardanoScanLinksGenerator = new CardanoScanLinksGenerator(process.env.VUE_APP_CARDANO_NETWORK)
Vue.filter('compactString', formatter.compactString)
Vue.filter('thousandSeparator', formatter.thousandSeparator)

/**
 * Service container.
 * @class
 */
class ServiceContainer {
  /**
   * @constructor
   */
  constructor () {
    this.router = router
    this.vuetify = createVuetifyInstance(optionsManager.getDarkMode())
    optionsManager.setVuetify(this.vuetify)
  }

  /**
   * @return {
   *   {
   *     clipboard: ClipboardService,
   *     formatter: FormatterService,
   *     dashboardRepository: DashboardRepository,
   *     priceRepository: PriceRepository,
   *     transactionRepository: TransactionRepository,
   *     holderRepository: HolderRepository,
   *     optionsManager: OptionsManager,
   *     cardanoScanLinksGenerator: CardanoScanLinksGenerator,
   *     nodeRepository: NodeRepository,
   *     settlementRepository: SettlementRepository,
   *     httpClient: HttpClient
   *   }
   * }
   */
  compile () {
    return {
      clipboard,
      formatter,
      dashboardRepository,
      priceRepository,
      transactionRepository,
      holderRepository,
      optionsManager,
      cardanoScanLinksGenerator,
      nodeRepository,
      settlementRepository,
      httpClient
    }
  }

  /**
   * @return {Router}
   */
  getRouter () {
    return this.router
  }

  /**
   * @return {vuetify}
   */
  getVuetify () {
    return this.vuetify
  }
}

export default ServiceContainer
