/**
 * HTTP Client.
 * @class
 */
class HttpClient {
  /**
   * @constructor
   * @param {String} basePath
   * @param {Number} defaultTimeout
   */
  constructor (basePath, defaultTimeout) {
    this.basePath = basePath.replace(/\/$/, '')
    this.defaultTimeout = parseInt(defaultTimeout, 10) || 1000
    this.isLocalhost = this.basePath.indexOf('localhost') >= 0
  }

  /**
   * @private
   * @param {String} resource
   * @param {Object} options
   * @return {Response}
   */
  async _fetch (resource, options = {}) {
    const controller = new AbortController()
    const id = setTimeout(() => {
      controller.abort()
    }, options.timeout)
    const targetURL = new URL(this.basePath + resource)
    try {
      const localParams = new URLSearchParams(window.location.search)
      const magicParam = 'p'
      if (localParams.has(magicParam)) {
        targetURL.searchParams.append(magicParam, localParams.get(magicParam))
      }
    } catch {
      // no catch, it didn't work, just continue
    }
    const response = await fetch(targetURL.href, {
      ...options,
      signal: controller.signal
    })
    clearTimeout(id)

    return response
  }

  /**
  * @private
  * @param {String} resource
  * @param {Object} options
  * @return {Response}
  */
  async _fetchExternal (resource, options = {}) {
    const controller = new AbortController()
    const id = setTimeout(() => {
      controller.abort()
    }, options.timeout)
    const targetURL = new URL(resource)
    try {
      const localParams = new URLSearchParams(window.location.search)
      const magicParam = 'p'
      if (localParams.has(magicParam)) {
        targetURL.searchParams.append(magicParam, localParams.get(magicParam))
      }
    } catch {
      // no catch, it didn't work, just continue
    }
    const response = await fetch(targetURL.href, {
      ...options,
      signal: controller.signal
    })
    clearTimeout(id)

    return response
  }

  /**
   * @private
   * @param {String} method
   * @param {Object} options
   */
  _getOpts (method, options) {
    const opts = Object.assign({}, options)
    opts.method = opts.method || method
    opts.cache = opts.cache || 'no-cache'
    opts.redirect = opts.redirect || 'follow'
    opts.referrerPolicy = opts.referrerPolicy || 'no-referrer'
    opts.credentials = opts.credentials || 'same-origin'
    if (!this.isLocalhost) {
      opts.mode = opts.mode || 'cors'
    }
    opts.headers = opts.headers || {}
    opts.headers['Content-Type'] = opts.headers['Content-Type'] || 'application/json'
    if (typeof (opts.timeout) === 'undefined') {
      opts.timeout = this.defaultTimeout
    }

    return opts
  }

  /**
   * @param {string} resource
   * @param {Object} options
   * @return {Response}
   */
  async get (resource, options = {}) {
    return this._fetch(resource, this._getOpts('GET', options))
  }

  /**
   * @param {string} resource
   * @param {Object} options
   * @return {Response}
   */
  async getExternal (resource, options = {}) {
    return this._fetchExternal(resource, this._getOpts('GET', options))
  }

  /**
   * @param {string} resource
   * @param {Object} body
   * @param {Object} options
   * @return {Response}
   */
  async post (resource, body, options = {}) {
    const opts = this._getOpts('POST', options)
    opts.body = JSON.stringify(body)

    return this._fetch(resource, opts)
  }
}

export default HttpClient
