<template>
  <div>
    <v-snackbar v-model="shown" id="global-toast">
      <div class="text-center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  install (Vue, options) {
    const rootNode = options.rootNode
    const selector = options.selector
    const ComponentClass = Vue.extend(this)
    const toast = new ComponentClass({
      vuetify: options.vuetify
    })
    toast.$mount()

    Vue.prototype.$toast = {
      mount () {
        if (!this.mounted) {
          rootNode.querySelector(selector).firstChild.appendChild(toast.$el)
          this.mounted = true
        }
      },
      show (text) {
        this.mount()
        toast.text = text
        toast.shown = true
      }
    }
  },
  data () {
    return {
      text: '',
      shown: false
    }
  }
}
</script>
