/**
 * Data storage.
 * @class
 */
class DataStorage {
  /**
   * @constructor
   * @param {String} prefix
   * @param {Storage} storage
   */
  constructor (prefix = '', storage) {
    this.prefix = prefix
    this.storage = storage
  }

  /**
   * @param {String} key
   * @return {String | null}
   */
  get (key) {
    return this.storage.getItem(this._getKey(key))
  }

  /**
   * @param {String} key
   * @param {String} value
   * @return {DataStorage}
   */
  set (key, value) {
    this.storage.setItem(this._getKey(key), value)

    return this
  }

  /**
   * @param {String} key
   * @return {DataStorage}
   */
  remove (key) {
    this.storage.removeItem(this._getKey(key))

    return this
  }

  /**
   * @private
   * @param {String} key
   * @return {String}
   */
  _getKey (key) {
    return this.prefix ? this.prefix + '_' + key : key
  }
}

export default DataStorage
