import Vue from 'vue'
import Vuebar from 'vuebar'
import App from './App.vue'
import cookies from './plugins/cookies'
import toast from './plugins/toast.vue'
import ServiceContainer from './service-container'
import store from './store'

const rootAppSelector = '#app'

const serviceContainer = new ServiceContainer()
const provide = serviceContainer.compile()

Vue.config.productionTip = false

Vue.use(Vuebar)
Vue.use(toast, {
  vuetify: serviceContainer.getVuetify(),
  rootNode: document,
  selector: rootAppSelector
})
Vue.use(cookies)

new Vue({
  store,
  provide,
  router: serviceContainer.getRouter(),
  vuetify: serviceContainer.getVuetify(),
  render: h => h(App)
}).$mount(rootAppSelector)

document.querySelectorAll('link.preload-style').forEach(el => { el.rel = 'stylesheet' })
