import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    days: parseInt(localStorage.getItem('days')) || 30,
    lastUpdate: null,
    isLoading: false,
    loading: {
      loading: false,
      loadingHistoricPrice: false
    }
  },
  mutations: {
    changeDays (state, newDays) {
      state.days = newDays
      localStorage.setItem('days', newDays)
    },
    changeLastUpdate (state, newUpdate) {
      state.lastUpdate = newUpdate
    },
    setLoading (state, { name, value }) {
      state.loading[name] = value
      state.isLoading = Object.values(state.loading).reduce((prev, current) => prev || current)
    }
  },
  actions: {}
})
