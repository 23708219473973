import moment from 'moment'
import DashboardData from '@/models/dashboard'
import HttpError from '@/errors/httpError'

/**
 * Dashboard repository.
 * @class
 */
class DashboardRepository {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor (httpClient) {
    this.httpClient = httpClient
    this.cache = {}
  }

  /**
   * @param {Number} days
   * @return {DashboardData}
   * @throws {HttpError}
   */
  async get (days) {
    let dashboard
    const path = '/json/' + days

    dashboard = this._getCached(path)
    if (dashboard === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      dashboard = new DashboardData()
      dashboard.setData(await response.json())
      this._setCached(path, dashboard)
    }

    return dashboard
  }

  /**
   * @private
   * @param {String} key
   * @return {DashboardData|undefined}
   */
  _getCached (key) {
    if (this.cache[key] && this.cache[key].expires && this.cache[key].expires > moment.utc()) {
      return this.cache[key].data
    }

    return undefined
  }

  /**
   * @private
   * @param {String} key
   * @param {DashboardData} data
   * @return {DashboardRepository}
   */
  _setCached (key, data) {
    this.cache[key] = {
      expires: moment.utc().add(5, 'minute'),
      data
    }

    return this
  }
}

export default DashboardRepository
