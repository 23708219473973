import moment from 'moment'
import Price from '@/models/price'
import HttpError from '@/errors/httpError'

/**
 * Price repository.
 * @class
 */
class PriceRepository {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor (httpClient) {
    this.httpClient = httpClient
    this.cache = {}
  }

  /**
   * @return {Price}
   * @throws {HttpError}
   */
  async getPrice () {
    let price
    const path = '/json/price'

    price = this._getCached(path)
    if (price === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      price = new Price(data.time, data.value)
      this._setCached(path, price)
    }

    return price
  }

  /**
   * @param {Number} days
   * @return {Price[]}
   * @throws {HttpError}
   */
  async getHistoricPrice (days) {
    let prices
    const path = '/json/price/historic/' + days

    prices = this._getCached(path)
    if (prices === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      prices = data.map(el => new Price(el.time, el.value))
      this._setCached(path, prices)

      if (!data || !data.length) {
        return []
      }
    }

    return prices
  }

  /**
   * @private
   * @param {String} key
   * @return {Price|Price[]|undefined}
   */
  _getCached (key) {
    if (this.cache[key] && this.cache[key].expires && this.cache[key].expires > moment.utc()) {
      return this.cache[key].data
    }

    return undefined
  }

  /**
   * @private
   * @param {String} key
   * @param {Price|Price[]} data
   * @return {PriceRepository}
   */
  _setCached (key, data) {
    this.cache[key] = {
      expires: moment.utc().add(1, 'minute'),
      data
    }

    return this
  }
}

export default PriceRepository
