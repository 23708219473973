import moment from 'moment'
import HolderData from '@/models/holder'
import HttpError from '@/errors/httpError'

/**
 * Holder repository.
 * @class
 */
class HolderRepository {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor (httpClient) {
    this.httpClient = httpClient
    this.cache = {}
  }

  /**
   * @param {Number} page
   * @return {HolderData[]}
   * @throws {HttpError}
   */
  async getHoldersPaginated (page = 1) {
    let holders
    const path = '/json/holders/' + page

    holders = this._getCached(path)
    if (holders === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      holders = (data && data.items) ? data.items.map(el => new HolderData(el.address, el.balance, el.share)) : []
      this._setCached(path, holders)
    }

    return holders
  }

  /**
   * @return {HolderData}
   * @throws {HttpError}
   */
  async getBuyback () {
    let buyback
    const path = '/json/buyback'

    buyback = this._getCached(path)
    if (buyback === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      buyback = new HolderData(data.address, data.balance)
      this._setCached(path, buyback)
    }

    return buyback
  }

  /**
   * @private
   * @param {String} key
   * @return {HolderData[]|undefined}
   */
  _getCached (key) {
    if (this.cache[key] && this.cache[key].expires && this.cache[key].expires > moment.utc()) {
      return this.cache[key].data
    }

    return undefined
  }

  /**
   * @private
   * @param {String} key
   * @param {HolderData[]} data
   * @return {HolderRepository}
   */
  _setCached (key, data) {
    this.cache[key] = {
      expires: moment.utc().add(1, 'minute'),
      data
    }

    return this
  }
}

export default HolderRepository
