/**
 * Options manager.
 * @class
 */
class OptionsManager {
  /**
   * @constant
   * @private
   * @default
   * @type {String}
   */
  DARK_MODE = 'dark_mode'

  /**
   * @constructor
   * @param {DataStorage} dataStorage
   */
  constructor (dataStorage) {
    this.dataStorage = dataStorage
    this.vuetify = null
    if (this.dataStorage.get(this.DARK_MODE) !== 'light') {
      this.dataStorage.set(this.DARK_MODE, 'dark')
    }
    this.darkMode = this.dataStorage.get(this.DARK_MODE) === 'dark'
  }

  /**
   * @param {vuetify} vuetify
   * @return {OptionsManager}
   */
  setVuetify (vuetify) {
    this.vuetify = vuetify

    return this
  }

  /**
   * @return {Boolean}
   */
  getDarkMode () {
    return this.darkMode
  }

  /**
   * @param {Boolean} darkMode
   * @return {Boolean}
   */
  setDarkMode (darkMode) {
    this.darkMode = darkMode
    this.dataStorage.set(this.DARK_MODE, darkMode ? 'dark' : 'light')
    if (this.vuetify) {
      this.vuetify.framework.theme.dark = darkMode
    }

    return darkMode
  }

  /**
   * @return {Boolean}
   */
  toggleDarkMode () {
    return this.setDarkMode(!this.darkMode)
  }
}

export default OptionsManager
