import moment from 'moment'

/**
 * NetworkStats
 * @class
 * @property {Number} bytes
 * @property {Number} users
 * @property {moment.Moment} startedAt
 */
class NetworkStats {
  /**
   * @constructor
   * @param {Number} bytes
   * @param {Number} users
   * @param {String} startedAt
   */
  constructor (bytes, users, startedAt) {
    this.bytes = bytes
    this.users = users
    this.startedAt = moment.utc(startedAt)
  }
}

export default NetworkStats
