/**
 * Vault data.
 * @class
 * @property {String} date
 * @property {Number} users
 * @property {Number} stakingAddresses
 * @property {Number} stakingBalance
 * @property {Number} earthNodes
 * @property {Number} addressesWithEarthNode
 * @property {Number} avgEarthNodesPerWallet
 * @property {Number} rewards
 */
class VaultData {
  /**
   * @constructor
   * @param {String} date
   * @param {Number} users
   * @param {Number} stakingAddresses
   * @param {Number} stakingBalance
   * @param {Number} earthNodes
   * @param {Number} addressesWithEarthNode
   * @param {Number} avgEarthNodesPerWallet
   * @param {Number} rewards
   */
  constructor (
    date,
    users,
    stakingAddresses,
    stakingBalance,
    earthNodes,
    addressesWithEarthNode,
    avgEarthNodesPerWallet,
    rewards
  ) {
    this.date = date
    this.users = users
    this.stakingAddresses = stakingAddresses
    this.stakingBalance = stakingBalance
    this.earthNodes = earthNodes
    this.addressesWithEarthNode = addressesWithEarthNode
    this.avgEarthNodesPerWallet = avgEarthNodesPerWallet
    this.rewards = rewards
  }
}

export default VaultData
