import configuration from './list'

export default {
  install (Vue, options) {
    const config = { ...configuration }
    config.list = { ...config.list }
    Vue.prototype.$cookies = Vue.observable({
      show: false,
      accepted: false,
      length: 0,
      list: {},
      writeLocalStorage (cname, value) {
        localStorage.setItem(cname, JSON.stringify(value))
      },
      readLocalStorage (cname) {
        let result = localStorage.getItem(cname)
        if (result) {
          result = JSON.parse(result)
        }

        return result
      },
      enableType (t) {
        if (['functional', 'analytics', 'marketing', 'essential'].includes(t)) {
          this.updateConsentsByType(t, true)
        } else {
          console.error(t + 'is not a suported type')
        }
      },
      disableType (t) {
        if (['functional', 'analytics', 'marketing', 'essential'].includes(t)) {
          this.updateConsentsByType(t, false)
        } else {
          console.error(t + 'is not a suported type')
        }
      },
      updateConsentsByType (type, enabled) {
        config.list[type].gtmConsent.forEach(consent => {
          this.updateGtagConsent(consent, enabled)
        })
      },
      updateGtagConsent (consent, enabled) {
        if (window.gtag) {
          window.gtag('consent', 'update', { [consent]: enabled ? 'granted' : 'denied' })
          let consents = this.readLocalStorage(config.gtmConsentsName) || []
          if (enabled) {
            consents = [...consents, consent]
          } else {
            consents = consents.filter(x => x !== consent)
          }
          this.writeLocalStorage(config.gtmConsentsName, consents)
        }
      },
      enableSelection (selection) {
        for (const type in selection) {
          if (selection[type]) {
            this.enableType(type)
          } else {
            this.disableType(type)
          }
        }
      },
      acceptCookies (selection) {
        this.enableSelection(selection)
        this.accepted = true
        window.location.reload()
      },
      reject () {
        this.show = false
      }
    })

    // eslint-disable-next-line no-new
    new Vue({
      watch: {
        '$cookies.accepted' (value) {
          this.$cookies.writeLocalStorage(config.cookieAcceptedName, value)
          if (value === true) {
            this.$cookies.show = false
          }
        }
      },
      created () {
        const areCookiesAccepted = this.$cookies.readLocalStorage(config.cookieAcceptedName)
        const gtmConsents = this.$cookies.readLocalStorage(config.gtmConsentsName)

        this.$cookies.accepted = !!areCookiesAccepted
        this.$cookies.show = !gtmConsents
      }
    })
  }
}
