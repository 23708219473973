export default {
  isNuxt: false,
  cookieConsentName: 'cookie-consent',
  cookieAcceptedName: 'cookie-accepted',
  gtmConsentsName: 'gtmConsents',
  list: {
    essential: {
      cookies: [],
      gtmConsent: ['security_storage', 'essential']
    },
    functional: {
      cookies: [],
      gtmConsent: ['functionality_storage', 'personalization_storage']
    },
    analytics: {
      cookies: [
        {
          id: 'google-analytics',
          name: 'Google analytics',
          description: 'Google Analytics is a simple, easy-to-use tool that helps website owners measure how users interact with website content. As a user navigates between web pages, Google Analytics provides website owners JavaScript tags (libraries) to record information about the page a user has seen, for example the URL of the page. The Google Analytics JavaScript libraries use HTTP cookies to "remember" what a user has done on previous pages / interactions with the website.'
        }
      ],
      gtmConsent: ['analytics_storage']
    },
    marketing: {
      cookies: [],
      gtmConsent: ['ad_storage']
    }
  }
}
