import VaultData from '@/models/vault'
import SupplyData from '@/models/supply'

/**
 * Dashboard data.
 * @class
 * @property {VaultData[]} vault
 * @property {SupplyData[]} supply
 * @property {AllocationData[]} allocations
 */
class DashboardData {
  /**
   * @constructor
   */
  constructor () {
    this.vault = []
    this.supply = []
    this.allocations = []
  }

  /**
   * @param {{vault: VaultData[], supply: SupplyData[], allocations: AllocationData[]}} data
   * @return {DashboardData}
   */
  setData (data) {
    this.setVaultData(data.vault)
    this.setSupplyData(data.supply)

    return this
  }

  /**
   * @param {VaultData[]} data
   * @return {DashboardData}
   */
  setVaultData (data) {
    if (data) {
      data.map(el => {
        this.vault.push(
          new VaultData(
            el.date,
            el.users,
            el.staking_addresses,
            el.staking_balance,
            el.earth_nodes,
            el.addresses_with_earth_node,
            el.avg_earth_nodes_per_wallet,
            el.rewards
          )
        )
      })
    }

    return this
  }

  /**
   * @param {SupplyData[]} data
   * @return {DashboardData}
   */
  setSupplyData (data) {
    if (data) {
      data.map(el => {
        this.supply.push(new SupplyData(el.date, el.circulating, el.max, el.total, el.addresses, el.wallets))
      })
    }

    return this
  }
}

export default DashboardData
