import moment from 'moment'

/**
 * Transaction
 * @class
 * @property {Number} block
 * @property {moment.Moment} time
 * @property {String} hash
 * @property {Number} index
 * @property {String} address
 * @property {Number} wmt
 */
class Transaction {
  /**
   * @constructor
   * @param {Number} block
   * @param {Number} time
   * @param {String} hash
   * @param {Number} index
   * @param {String} address
   * @param {Number} wmt
   */
  constructor (block, time, hash, index, address, wmt) {
    this.block = block
    this.time = moment.utc(time * 1000)
    this.hash = hash
    this.index = index
    this.address = address
    this.wmt = wmt
  }
}

export default Transaction
