/**
 * CardanoScan links generator.
 * @class
 */
class CardanoScanLinksGenerator {
  /**
   * @constructor
   * @param {String} defaultNetwork
   */
  constructor (defaultNetwork) {
    this.defaultNetwork = defaultNetwork
  }

  /**
   * @param {Number} epoch
   * @param {String|undefined} network
   * @return {String}
   */
  getEpochLink (epoch, network = undefined) {
    return this._getBaseUrl(network) + '/epoch/' + epoch
  }

  /**
   * @param {Number} block
   * @param {String|undefined} network
   * @return {String}
   */
  getBlockLink (block, network = undefined) {
    return this._getBaseUrl(network) + '/block/' + block
  }

  /**
   * @param {String} transaction
   * @param {String|undefined} network
   * @return {String}
   */
  getTransactionLink (transaction, network = undefined) {
    return this._getBaseUrl(network) + '/transaction/' + transaction
  }

  /**
   * @param {String} address
   * @param {String|undefined} network
   * @return {String}
   */
  getAddressLink (address, network = undefined) {
    return this._getBaseUrl(network) + '/address/' + address
  }

  /**
   * @param {String|undefined} network
   * @return {String}
   */
  getNetworkName (network = undefined) {
    if (!network) {
      network = this.defaultNetwork
    }

    return network[0].toUpperCase() + network.slice(1)
  }

  /**
   * @private
   * @param {String|undefined} network
   * @return {String}
   */
  _getBaseUrl (network = undefined) {
    if (!network) {
      network = this.defaultNetwork
    }

    return network === 'testnet' ? 'https://testnet.cardanoscan.io' : 'https://cardanoscan.io'
  }
}

export default CardanoScanLinksGenerator
