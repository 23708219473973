/* eslint-disable camelcase */
/**
 * AirNode.
 * @class
 * @property {String} did
 * @property {String} description
 * @property {String} location
 * @property {String} latitude
 * @property {String} longitude
 * @property {Number} radius
 * @property {String} last_seen_at
 * @property {Number} sessions_pre_authenticated
 * @property {Number} sessions_authenticated
 * @property {Number} users
 * @property {Number} bytes
 */
class AirNode {
  /**
   * @constructor
   * @param {String} did
   * @param {String} description
   * @param {String} location
   * @param {String} latitude
   * @param {String} longitude
   * @param {Number} radius
   * @param {String} last_seen_at
   * @param {Number} sessions_pre_authenticated
   * @param {Number} sessions_authenticated
   * @param {Number} users
   * @param {Number} bytes
   */
  constructor (did, description, location, latitude, longitude, radius, last_seen_at, sessions_pre_authenticated, sessions_authenticated, users, bytes) {
    this.did = did
    this.description = description
    this.location = location
    this.latitude = latitude
    this.longitude = longitude
    this.radius = radius
    this.last_seen_at = last_seen_at
    this.sessions_pre_authenticated = sessions_pre_authenticated
    this.sessions_authenticated = sessions_authenticated
    this.users = users
    this.bytes = bytes
  }
}

export default AirNode
