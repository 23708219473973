/* eslint-disable camelcase */
/**
 * Settlement.
 * @class
 * @property {Number} block
 * @property {String} tx
 * @property {Number} bytes_incoming
 * @property {Number} bytes_outgoing
 */
class Settlement {
  /**
   * @constructor
   * @param {Number} block
   * @param {String} tx
   * @param {Number} bytes_incoming
   * @param {Number} bytes_outgoing
   */
  constructor (block, tx, bytes_incoming, bytes_outgoing) {
    this.block = block
    this.tx = tx
    this.bytes_incoming = bytes_incoming
    this.bytes_outgoing = bytes_outgoing
  }
}

export default Settlement
