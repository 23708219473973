/**
 * Formatter service.
 * @class
 */
class FormatterService {
  /**
   * @param {String} value
   * @param {Number} start
   * @param {Number} end
   * @return {String}
   */
  compactString (value, start = 8, end = 8) {
    if (value.length > (start + end)) {
      return value.slice(0, start) + '[...]' + value.slice(-end)
    }

    return value
  }

  /**
   * @param {String} value
   * @param {String} char
   * @return {String}
   */
  thousandSeparator (value, char = ',') {
    const int = value.toString().split('.')[0]
    const dec = value.toString().split('.')[1] || '000000'

    return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, char) + '.' + dec
  }

  /**
   * @return {Object.<String, Function>}
   */
  getFilters () {
    return {
      compactString: this.compactString,
      thousandSeparator: this.thousandSeparator
    }
  }
}

export default FormatterService
