<template>
  <v-card
    v-if="$cookies.show"
    elevation="2"
    class="cookies-modal pa-5 rounded-xl"
    color="background darken-2"
    outlined
  >
    <v-card-title>Cookie policy</v-card-title>
    <v-card-text>We use cookies to provide you with the best possible experience. They also allow us to analyze user behavior in order to constantly improve the website for you. Read more about our cookies policy <router-link :to="{ name: 'cookies' }" >here</router-link>.</v-card-text>
    <v-card-actions>
      <v-btn class="cookies-modal__close" icon @click="() => $cookies.reject()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-col>
        <v-row class="d-flex justify-space-between mb-5">
          <v-checkbox v-model="isEssentialSelected" disabled label="Essential" class="mr-5"></v-checkbox>
          <v-checkbox v-model="isFunctionalSelected" label="Functional" color="text darken-1" class="mr-5"></v-checkbox>
          <v-checkbox v-model="isAnalyticsSelected" label="Analytics" color="text darken-1" class="mr-5"></v-checkbox>
          <v-checkbox v-model="isMarketingSelected" label="Marketing" color="text darken-1" class="mr-5"></v-checkbox>
        </v-row>
        <v-row class="d-flex justify-end flex-column flex-sm-row">
          <v-btn elevation="2" outlined color="text" class="mb-2 mr-sm-5 rounded-lg" @click="acceptSelected">Accept Selected</v-btn>
          <v-btn elevation="2" color="success" class="rounded-lg" @click="acceptAll">Accept All</v-btn>
        </v-row>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      isEssentialSelected: true,
      isAnalyticsSelected: false,
      isFunctionalSelected: false,
      isMarketingSelected: false
    }
  },
  methods: {
    acceptSelected () {
      const selected = {
        essential: this.isEssentialSelected,
        analytics: this.isAnalyticsSelected,
        functional: this.isFunctionalSelected,
        marketing: this.isMarketingSelected
      }
      this.$cookies.acceptCookies(selected)
    },
    acceptAll () {
      const selected = {
        essential: true,
        analytics: true,
        functional: true,
        marketing: true
      }
      this.$cookies.acceptCookies(selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.cookies-modal {
  z-index: 1000;
  width: min(600px, 100vw - 20px);
  position: fixed;
  bottom: 10px;
  right: 10px;
  &__close{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  ::v-deep {
    .v-input--selection-controls__ripple {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
    }
  }
}
</style>
