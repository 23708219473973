import Discord from '@/components/icons/Discord.vue'
import SwitchDark from '@/components/icons/SwitchDark.vue'
import SwitchLight from '@/components/icons/SwitchLight.vue'
import Telegram from '@/components/icons/Telegram.vue'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import {
  background, baseColors, border, text
} from '@/assets/colors.js'

Vue.use(Vuetify)

export default function createVuetifyInstance (darkMode) {
  return new Vuetify({
    theme: {
      dark: darkMode,
      options: { customProperties: true },
      themes: {
        dark: {
          ...baseColors,
          text: text.dark,
          background: background.dark,
          borderOpaque: border.dark.opaque
        },
        light: {
          ...baseColors,
          text: text.light,
          background: background.light,
          borderOpaque: border.light.opaque
        }
      }
    },
    icons: {
      values: {
        discord: {
          component: Discord
        },
        telegram: {
          component: Telegram
        },
        switchLight: {
          component: SwitchLight
        },
        switchDark: {
          component: SwitchDark
        }
      }
    }
  })
}
