const white = '#FFFFFF'
const black = '#000000'

const green = {
  lighten4: '#ECFDF5',
  lighten3: '#A7F3D0',
  lighten2: '#6EE7B7',
  lighten1: '#34D399',
  base: '#10B981',
  darken1: '#059669',
  darken2: '#047857',
  darken3: '#064E3B'
}
const red = {
  lighten4: '#FFEFED',
  lighten3: '#FED7D2',
  lighten2: '#F1998E',
  lighten1: '#E85C4A',
  base: '#E11900',
  darken1: '#AB1300',
  darken2: '#870F00',
  darken3: '#5A0A00'
}
const yellow = {
  lighten4: '#FFFAF0',
  lighten3: '#FFF2D9',
  lighten2: '#FFE3AC',
  lighten1: '#FFCF70',
  base: '#FFC043',
  darken1: '#BC8B2C',
  darken2: '#997328',
  darken3: '#674D1B'
}
const blue = {
  lighten4: '#F0F9FF',
  lighten3: '#BAE6FD',
  lighten2: '#7DD3FC',
  lighten1: '#38BDF8',
  base: '#0EA5E9',
  darken1: '#0284C7',
  darken2: '#0369A1',
  darken3: '#0C4A6E'
}
const orange = {
  lighten4: '#FFF1E6',
  lighten3: '#FFD6B3',
  lighten2: '#FFBB80',
  lighten1: '#FFA04D',
  base: '#FF7600',
  darken1: '#E66A00',
  darken2: '#B35300',
  darken3: '#803B00'
}
const purple = {
  lighten4: '#F4E9FC',
  lighten3: '#DDBCF6',
  lighten2: '#C68FF0',
  lighten1: '#AF62EA',
  base: '#8C1FE0',
  darken1: '#7118B6',
  darken2: '#58138D',
  darken3: '#3F0E65'
}
const pink = {
  lighten4: '#FEE7F0',
  lighten3: '#FDCEE2',
  lighten2: '#FB9DC6',
  lighten1: '#F85498',
  base: '#F51A74',
  darken1: '#DC1768',
  darken2: '#AC1251',
  darken3: '#7A0D3A'
}
const grey = {
  lighten5: '#F5F5F6',
  lighten4: '#EBECED',
  lighten3: '#E1E2E4',
  lighten2: '#CECFD2',
  lighten1: '#A9ABB0',
  base: '#535860',
  darken1: '#272D37',
  darken2: '#1D232E',
  darken3: '#131925',
  darken4: '#09101C'
}

const background = {
  dark: {
    base: black,
    darken1: grey.darken3,
    darken2: grey.darken2
  },
  light: {
    base: white,
    darken1: grey.lighten5,
    darken2: grey.lighten4
  }
}

const text = {
  dark: {
    base: white,
    darken1: grey.lighten2,
    darken2: grey.lighten1
  },
  light: {
    base: black,
    darken1: grey.darken2,
    darken2: grey.darken1
  }
}

const border = {
  dark: {
    opaque: grey.darken1,
    transparent: '#FFFFFF29'
  },
  light: {
    opaque: grey.lighten3,
    transparent: '#151A1E29'
  }
}

const baseColors = {
  white,
  black,
  red,
  grey,
  green,
  yellow,
  blue,
  orange,
  purple,
  pink,
  primary: purple,
  secondary: pink,
  info: blue,
  success: green,
  accent: blue,
  error: red,
  warning: yellow,
  default: orange,
  dark: grey.darken4
}

const graphColors = [baseColors.primary.base, baseColors.secondary.base, baseColors.default.base, baseColors.info.base, baseColors.success.base]

export {
  baseColors,
  background,
  text,
  graphColors,
  border
}
