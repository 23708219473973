/**
 * HTTP error.
 * @class
 * @extends Error
 */
class HttpError extends Error {
  /**
   * @constructor
   * @param {Number} code
   * @param {Response} response
   */
  constructor (code, response) {
    super()
    this.code = code
    this.response = response
    this.data = undefined
  }

  /**
   * @return {String|Object|null}
   */
  async getData () {
    if (this.data === undefined) {
      this.data = (this.response && typeof (this.response.text) === 'function') ? await this.response.text() : null
      if (this.data !== null) {
        try {
          this.data = JSON.parse(this.data)
        } catch (ex) {}
      }
    }

    return this.data
  }
}

export default HttpError
