import moment from 'moment'
import Transaction from '@/models/transaction'
import HttpError from '@/errors/httpError'

/**
 * Transaction repository.
 * @class
 */
class TransactionRepository {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor (httpClient) {
    this.httpClient = httpClient
    this.cache = {}
  }

  /**
   * @param {Number} page
   * @return {Transaction[]}
   * @throws {HttpError}
   */
  async getTransactionsPaginated (page = 1) {
    let transactions
    const path = '/json/transactions/' + page

    transactions = this._getCached(path)
    if (transactions === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      transactions = (data && data.items) ? data.items.map(el => new Transaction(el.block, el.time, el.hash, el.index, el.address, el.wmt)) : []
      this._setCached(path, transactions)
    }

    return transactions
  }

  /**
   * @private
   * @param {String} key
   * @return {Transaction[]|undefined}
   */
  _getCached (key) {
    if (this.cache[key] && this.cache[key].expires && this.cache[key].expires > moment.utc()) {
      return this.cache[key].data
    }

    return undefined
  }

  /**
   * @private
   * @param {String} key
   * @param {Transaction[]} data
   * @return {TransactionRepository}
   */
  _setCached (key, data) {
    this.cache[key] = {
      expires: moment.utc().add(1, 'minute'),
      data
    }

    return this
  }
}

export default TransactionRepository
