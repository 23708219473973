/**
 * Holder data.
 * @class
 * @property {String} address
 * @property {Number} balance
 * @property {Number} share
 */
class HolderData {
  /**
   * @constructor
   * @param {String} address
   * @param {Number} balance
   * @param {Number} share
   */
  constructor (address, balance, share) {
    this.address = address
    this.balance = balance
    this.share = share
  }
}

export default HolderData
