/* eslint-ignore camelcase */
import HttpError from '@/errors/httpError'
import AirNode from '@/models/airNode'
import NetworkStats from '@/models/NetworkStats'
import moment from 'moment'

/**
 * Node repository.
 * @class
 */
class NodeRepository {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor (httpClient) {
    this.httpClient = httpClient
    this.cache = {}
  }

  /**
   * @return {Object.<airNodes: Node[], backhauls: Node[]>}
   * @throws {HttpError}
   */
  async getNodes () {
    const path = 'https://airnode.worldmobile.net/api/public/airnodes'
    let airNodes = this._getCached(path + '_airNodes')
    let backhauls = this._getCached(path + '_backhauls')

    if (airNodes === undefined) {
      const response = await this.httpClient.getExternal(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()

      backhauls = []
      this._setCached(path + '_backhauls', backhauls)

      airNodes = []

      data.forEach(el => {
        const lat = el.latitude ? parseFloat(el.latitude) : null
        const long = el.longitude ? parseFloat(el.longitude) : null
        airNodes.push(
          new AirNode(
            el.did, el.description, '', lat, long, 180, '2022-01-01T00:00:00', 0, 0, 0, 0
          )
        )
      })
      this._setCached(path + '_airNodes', airNodes)
    }

    return {
      airNodes: airNodes,
      backhauls: backhauls
    }
  }

  /**
   * @return {NetworkStats}
   * @throws {HttpError}
   */
  async getNetworkStats () {
    let stats
    const path = 'https://airnode.worldmobile.net/api/public/networkstats'

    stats = this._getCached(path)
    if (stats === undefined) {
      const response = await this.httpClient.getExternal(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      stats = new NetworkStats(data.bytes, data.users, data.started_at)
      this._setCached(path, stats)
    }

    return stats
  }

  /**
   * @private
   * @param {String} key
   * @return {Node[]|undefined}
   */
  _getCached (key) {
    if (this.cache[key] && this.cache[key].expires && this.cache[key].expires > moment.utc()) {
      return this.cache[key].data
    }

    return undefined
  }

  /**
   * @private
   * @param {String} key
   * @param {Node[]} data
   * @return {NodeRepository}
   */
  _setCached (key, data) {
    this.cache[key] = {
      expires: moment.utc().add(1, 'minute'),
      data
    }

    return this
  }
}

export default NodeRepository
