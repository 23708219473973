/**
 * Clipboard service.
 * @class
 */
class ClipboardService {
  /**
   * Copy text to clipboard.
   * @param {String} text
   * @return {ClipboardService}
   */
  copy (text) {
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(text)
    } else {
      if (window.clipboardData) {
        window.clipboardData.setData('Text', text)
      } else {
        const textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'absolute'
        textarea.style.left = '-1000px'
        textarea.style.top = '-1000px'
        document.body.appendChild(textarea)
        const selection = document.getSelection()
        const range = document.createRange()
        range.selectNode(textarea)
        selection.removeAllRanges()
        selection.addRange(range)
        try {
          document.execCommand('copy')
          selection.removeAllRanges()
          document.body.removeChild(textarea)
        } catch (e) {
          document.body.removeChild(textarea)
          window.prompt('Copy to clipboard: Ctrl C, Enter', text)
        }
      }
    }

    return this
  }
}

export default ClipboardService
