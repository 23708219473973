/**
 * Supply data.
 * @class
 * @property {String} date
 * @property {Number} circulating
 * @property {Number} max
 * @property {Number} total
 * @property {Number} addresses
 * @property {Number} wallets
 */
class SupplyData {
  /**
   * @constructor
   * @param {String} date
   * @param {Number} circulating
   * @param {Number} max
   * @param {Number} total
   * @param {Number} addresses
   * @param {Number} wallets
   */
  constructor (date, circulating, max, total, addresses, wallets) {
    this.date = date
    this.circulating = circulating
    this.max = max
    this.total = total
    this.addresses = addresses
    this.wallets = wallets
  }
}

export default SupplyData
