/* eslint-ignore camelcase */
import HttpError from '@/errors/httpError'
import Settlement from '@/models/settlement'
import moment from 'moment'

/**
 * Settlement repository.
 * @class
 */
class SettlementRepository {
  /**
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor (httpClient) {
    this.httpClient = httpClient
    this.cache = {}
  }

  /**
   * @param {Number} page
   * @return {Transaction[]}
   * @throws {HttpError}
   */
  async getSettlementsPaginated (page = 1) {
    const path = `/json/settlements/${page}`
    let settlements = this._getCached(path + '_settlements')

    if (settlements === undefined) {
      const response = await this.httpClient.get(path)
      if (!response || !response.ok) {
        throw new HttpError(response.status, response)
      }

      const data = await response.json()
      const settlementsItems = (data && data.items) ? data.items.map(el => new Settlement(el.block, el.tx, el.bytes_incoming, el.bytes_outgoing)) : []

      settlements = {
        items: settlementsItems,
        pages: data.pages,
        total: data.total
      }

      this._setCached(path + '_settlements', settlements)
    }

    return settlements
  }

  /**
   * @private
   * @param {String} key
   * @return {Settlement[]|undefined}
   */
  _getCached (key) {
    if (this.cache[key] && this.cache[key].expires && this.cache[key].expires > moment.utc()) {
      return this.cache[key].data
    }

    return undefined
  }

  /**
   * @private
   * @param {String} key
   * @param {Settlement[]} data
   * @return {SettlementRepository}
   */
  _setCached (key, data) {
    this.cache[key] = {
      expires: moment.utc().add(1, 'minute'),
      data
    }

    return this
  }
}

export default SettlementRepository
